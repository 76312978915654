import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure you're using Bootstrap 5.x
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import axios from 'axios';

const AdminSupportReply = () => {
    const { ticket_id } = useParams();
    const [comments, setComments] = useState([]);
    const [ticketNo, setTicketNo] = useState('');
    const [newComment, setNewComment] = useState('');
    const userId = sessionStorage.getItem('user_id');
    const [problem, setProblem] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchTicketDetails = useCallback(async () => {
        try {
            const response = await axios.get(`https://www.server.torqoise.com/api/tickets/${ticket_id}/`);
            setTicketNo(response.data.ticket_no);
            setProblem(response.data.problem);
            setStatus(response.data.status);
        } catch (error) {
            console.error('Error fetching ticket details:', error);
        }
    }, [ticket_id]);

    const fetchComments = useCallback(async () => {
        try {
            const response = await axios.get(`https://www.server.torqoise.com/api/comments/${ticket_id}/`);
            setComments(response.data);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    }, [ticket_id]);

    useEffect(() => {
        // Scroll to top when component mounts
        window.scrollTo(0, 0);

        fetchTicketDetails();
        fetchComments();
    }, [fetchTicketDetails, fetchComments, ticket_id]);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://www.server.torqoise.com/api/comments/', {
                description: newComment,
                ticket_id: ticket_id,
                user_id: userId
            });
            if (response.status === 200) {
                const newCommentData = response.data;
                setComments(prevComments => [newCommentData, ...prevComments]);
                setNewComment('');
                fetchComments();
                setTimeout(() => {
                    setLoading(false);
                    toast.success('Message sent successfully!', {
                        style: { backgroundColor: 'green', color: 'white', fontSize: 'bolder' },
                    });
                }, 3000); // 3 seconds delay for the toast
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.error('Error creating comment:', error);
            setTimeout(() => {
                setLoading(false);
                toast.error('Fail to send message!', {
                    style: { backgroundColor: 'red', color: 'white', fontSize: 'bolder' },
                });
            }, 3000); // 3 seconds delay for the toast
        }
    };

    return (
        <div className="container my-4">
            <div className="row mb-4">
                <div className="col-12 text-center mb-4">
                    <h2><strong>Ticket No: </strong>{ticketNo}</h2>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12 col-md-10">
                    <h5><strong>Subject :</strong> {problem}</h5>
                </div>
                <div className="col-12 col-md-2">
                    <h5><strong>Status :</strong> {status === 1 ? 'Open' : 'Closed'}</h5>
                </div>
            </div>

            {status === 1 && (
                <div className="row mb-4">
                    <div className="col-12 col-md-10">
                        <Form>
                            <Form.Group controlId="formNewComment">
                                <Form.Label>New Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-12 col-md-2 text-end">
                        <Button variant="primary" onClick={handleSubmit} disabled={loading}>Reply</Button>
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-12">
                    <hr />
                    {comments.map((comment) => (
                        <div key={comment.id} className="mb-3">
                            <div className="row">
                                <div className="col-12 col-md-2 mb-2 mb-md-0">
                                    <p><strong>{comment.user_role === 1 ? 'Support' : comment.user_name}</strong></p>
                                </div>
                                <div className="col-12 col-md-8 mb-2 mb-md-0">
                                    {comment.description}
                                </div>
                                <div className="col-12 col-md-2">
                                    <small>{new Date(comment.created_at).toLocaleString()}</small>
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>
            </div>

            {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999
                }}>
                    <RotatingLines
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                    />
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default AdminSupportReply;
