import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from '../../Dashboard/Table';
import 'react-toastify/dist/ReactToastify.css';
import ReplyIcon from '@mui/icons-material/Reply';
import { RotatingLines } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';

const UserSupport = () => {
    const [tickets, setTickets] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ problem: '', description: '' });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const columns = ['Id', 'Ticket_No', 'Email', 'Problem', 'Status', 'Created_at', 'Actions'];
    const email = sessionStorage.getItem('email');

    useEffect(() => {
        // Scroll to top when component mounts
        window.scrollTo(0, 0);

        getTickets();
    }, []);

    const getTickets = async () => {
        try {
            const userId = sessionStorage.getItem('user_id');
            const response = await fetch(`https://www.server.torqoise.com/api/ticketsbyuserid/${userId}/`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            let counter = 1;
            const rearrangedData = data.map((item) => ({
                Id: counter++,
                Ticket_No: item.ticket_no,
                Email: item.email,
                Problem: item.problem,
                Status: item.status === 1 ? (<span style={{color:'green',fontWeight:'bold'}}>Open</span>) : (<span style={{color:'red',fontWeight:'bold'}}>Closed</span>),
                Created_at: new Date(item.created_at).toLocaleString(),
                Actions: (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                        <Button
                            variant="primary"
                            onClick={() => handleViewClick(item.id)}
                        >
                            <ReplyIcon />
                        </Button>
                    </div>
                )
            }));
            setTickets(rearrangedData);
        } catch (error) {
            console.error('Error fetching tickets:', error);
        }
    };

    const handleViewClick = (ticketId) => {
        navigate(`/support_reply/${ticketId}`);
    };

    const handleSubmit = async () => {
        setShowModal(false); // Close the modal first
        setLoading(true);  // Show loader after the modal is closed
        const userId = sessionStorage.getItem('user_id');
        try {
            const response = await fetch('https://www.server.torqoise.com/api/tickets/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...formData, email, user_id: userId })
            });
            if (response.ok) {
                const newTicket = await response.json();
                setTickets([...tickets, newTicket]);
                getTickets(); // Refresh tickets list
                setTimeout(() => {
                    setLoading(false);  // Hide loader after delay
                    toast.success('Ticket created successfully!', {
                        style: {
                            backgroundColor: 'green',
                            color: 'white'
                        }
                    });
                }, 3000); // 3 seconds delay for the toast

                // Clear form fields
                setFormData({ problem: '', description: '' });
            } else {
                console.error('Error creating ticket');
                setTimeout(() => {
                    setLoading(false);  // Hide loader after delay
                    toast.error('Failed to create ticket.', {
                        style: {
                            backgroundColor: 'red',
                            color: 'white'
                        }
                    });
                }, 3000); // 3 seconds delay for the toast
            }
        } catch (error) {
            console.error('Error creating ticket:', error);
            setTimeout(() => {
                setLoading(false);  // Hide loader after delay
                toast.error('Failed to create ticket.', {
                    style: {
                        backgroundColor: 'red',
                        color: 'white'
                    }
                });
            }, 3000); // 3 seconds delay for the toast
        }
    };

    return (
        <div>
            <Button variant="primary" onClick={() => setShowModal(true)} style={{ marginLeft: '20px' }}>New Ticket</Button>
            <Table
                columns={columns}
                data={tickets}
                tableHeadBgColor="gray"
                searchable={true}
                tableHeadFontFamily="Arial"
            />
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formEmail">
                            <Form.Label><strong>Email :</strong></Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                readOnly
                            />
                        </Form.Group>
                        <br />
                        <Form.Group controlId="formProblem">
                            <Form.Label><strong>Problem :</strong></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                value={formData.problem}
                                onChange={(e) => setFormData({ ...formData, problem: e.target.value })}
                            />
                        </Form.Group>
                        <br />
                        <Form.Group controlId="formDescription">
                            <Form.Label><strong>Description :</strong></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={loading}>Submit</Button>
                </Modal.Footer>
            </Modal>
            {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999
                }}>
                    <RotatingLines
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                    />
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default UserSupport;
