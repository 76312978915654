import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from '../../Dashboard/Table';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import ReplyIcon from '@mui/icons-material/Reply';
import EditNoteIcon from '@mui/icons-material/EditNote';

const AdminSupport = () => {
    const [tickets, setTickets] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [updatedProblem, setUpdatedProblem] = useState('');
    const [updatedStatus, setUpdatedStatus] = useState('');
    const navigate = useNavigate();

    const columns = ['Id', 'Ticket_No', 'Email', 'Problem', 'Status', 'Created_at', 'Actions'];

    useEffect(() => {
        getTickets();
    }, []);

    const getTickets = async () => {
        try {
            const response = await fetch('https://www.server.torqoise.com/api/tickets/');
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            let counter = 1;
            const rearrangedData = data.map((item) => ({
                Id: counter++,
                Ticket_No: item.ticket_no,
                Email: item.email,
                Problem: item.problem,
                Status: item.status === 1 ? (<span style={{color:'green',fontWeight:'bold'}}>Open</span>) : (<span style={{color:'red',fontWeight:'bold'}}>Closed</span>),
                Created_at: new Date(item.created_at).toLocaleString(),
                Actions: (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                        <Button
                            variant="primary"
                            onClick={() => handleViewClick(item.id)}
                        >
                            <ReplyIcon />
                        </Button>
                        <Button
                            variant="success"
                            onClick={() => handleEditClick(item)}
                        >
                            <EditNoteIcon />
                        </Button>
                    </div>
                )
            }));
            setTickets(rearrangedData);
        } catch (error) {
            console.error('Error fetching tickets:', error);
        }
    };

    const handleViewClick = (ticketId) => {
        navigate(`/support_reply/${ticketId}`);
    };

    const handleEditClick = (ticket) => {
        setSelectedTicket(ticket);
        setUpdatedProblem(ticket.problem);
        setUpdatedStatus(ticket.status === 1 ? 'Open' : 'Closed');
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedTicket(null);
        setUpdatedProblem('');
        setUpdatedStatus('');
    };

    const handleSaveChanges = async () => {
        if (!selectedTicket) return;

        try {
            const response = await fetch(`https://www.server.torqoise.com/api/tickets/${selectedTicket.id}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    problem: updatedProblem,
                    status: updatedStatus === 'Open' ? 1 : 0,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update ticket');
            }

            toast.success('User updated successfully!', {
                style: { backgroundColor: 'green', color: 'white', fontSize: 'bolder' },
              });
            handleCloseModal();
            getTickets(); // Refresh the ticket list after update
        } catch (error) {
            toast.error('Failed to update. Please try again.', {
                style: { backgroundColor: 'red', color: 'white', fontSize: 'bolder' },
              });
            // console.error('Error updating ticket:', error);
        }
    };

    return (
        <div>
            <center><h2>Admin Ticket Support</h2></center>
            <Table
                columns={columns}
                data={tickets}
                tableHeadBgColor="gray"
                searchable={true}
                tableHeadFontFamily="Arial"
            />

            {/* Edit Ticket Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formId" style={{display:'none'}}>
                            <Form.Label>Id</Form.Label>
                            <Form.Control
                                type="text"
                                value={selectedTicket?.id || ''}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group controlId="formTicketNo">
                            <Form.Label>Ticket No</Form.Label>
                            <Form.Control
                                type="text"
                                value={selectedTicket?.ticket_no || ''}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={selectedTicket?.email || ''}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group controlId="formProblem">
                            <Form.Label>Problem</Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedProblem}
                                onChange={(e) => setUpdatedProblem(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                value={updatedStatus}
                                onChange={(e) => setUpdatedStatus(e.target.value)}
                            >
                                <option value="Open">Open</option>
                                <option value="Closed">Closed</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Toast Container */}
            <ToastContainer />
        </div>
    );
};

export default AdminSupport;
