import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, LineController } from 'chart.js';
import 'react-toastify/dist/ReactToastify.css';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, LineController);

const AdminHomePage = () => {
  const [stats, setStats] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
    
    axios.get('https://www.server.torqoise.com/api/statistics/')
      .then(response => {
        setStats(response.data);
      })
      .catch(error => {
        toast.error('Failed to fetch statistics. Please check your network connection.');
        console.error('Error fetching statistics:', error);
      });
  }, []);

  useEffect(() => {
    if (stats) {
      const ctx = chartRef.current.getContext('2d');
      const keyData = {
        labels: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ],
        datasets: [
          {
            label: 'Basic',
            data: stats.monthly_licenses.basic,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
          },
          {
            label: 'Standard',
            data: stats.monthly_licenses.standard,
            borderColor: 'rgba(153, 102, 255, 1)',
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
          },
          {
            label: 'Premium',
            data: stats.monthly_licenses.premium,
            borderColor: 'rgba(255, 159, 64, 1)',
            backgroundColor: 'rgba(255, 159, 64, 0.6)',
          },
        ],
      };

      if (chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }

      chartRef.current.chartInstance = new Chart(ctx, {
        type: 'line',
        data: keyData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: true },
            tooltip: { enabled: true },
          },
          scales: {
            x: {
              type: 'category',
            },
            y: {
              type: 'linear',
            },
          },
        },
      });
    }
  }, [stats]);

  return (
    <Container>
      <Row className="mt-5">
        <Col md={3} style={{marginBottom:'20px'}}>
          <Card>
            <Card.Body style={{ height: '100px', boxShadow: "0px 4px 4px 4px gray", borderRadius: '10px', background: 'linear-gradient(45deg, red, white)', color: 'white' }}>
              <Card.Title>Total Users</Card.Title>
              <Card.Text>{stats?.total_users}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} style={{marginBottom:'20px'}}>
          <Card>
            <Card.Body style={{ height: '100px', boxShadow: "0px 4px 4px 4px gray", borderRadius: '10px', background: 'linear-gradient(45deg, green, white)', color: 'white' }}>
              <Card.Title>Total Tickets Open</Card.Title>
              <Card.Text>{stats?.total_tickets_open}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} style={{marginBottom:'20px'}}>
          <Card>
            <Card.Body style={{ height: '100px', boxShadow: "0px 4px 4px 4px gray", borderRadius: '10px', background: 'linear-gradient(45deg, purple, white)', color: 'white' }}>
              <Card.Title>Total Tickets Closed</Card.Title>
              <Card.Text>{stats?.total_tickets_closed}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} style={{marginBottom:'20px'}}>
          <Card>
            <Card.Body style={{ height: '100px', boxShadow: "0px 4px 4px 4px gray", borderRadius: '10px', background: 'linear-gradient(45deg, blue, white)', color: 'white' }}>
              <Card.Title>Total Tickets Created</Card.Title>
              <Card.Text>{stats?.total_tickets_created}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Card>
            <Card.Body style={{ boxShadow: "0px 4px 4px 4px gray", borderRadius: '10px' }}>
              <Card.Title style={{ padding: '20px' }}>Total Number of Keys Generated (Monthly)</Card.Title>
              <hr />
              <div style={{ height: '400px', padding: '20px' }}>
                <canvas ref={chartRef} id="myChart" style={{ width: '100%', height: '100%' }}></canvas>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default AdminHomePage;
