import React,{useEffect,useState,useRef} from 'react';
import './Nav.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate } from 'react-router-dom';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

const Nav = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sideDropdown, setSideDropdown] = useState(false);
  const dropdownRef = useRef(); // Ref for the dropdown container

  const handleLogout = () => {
    sessionStorage.removeItem('user_id');
    navigate('/login');
  };

  const openMenu = () => {
    setIsOpen(!isOpen);
  };

  const opensideMenu = () =>{
    setSidebarOpen(!sidebarOpen);
  };

  const handleSideDropdown = () =>{
    setSideDropdown(!sideDropdown);
  }

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  }

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className='Main-Navbar'>
       <div className='Navbar-first'>
        <button className='menu-icon' onClick={opensideMenu}><MenuIcon style={{fontSize:'30px'}}/></button>
        <h1 className='dashname'>Dashboard</h1>
       </div>

       <div className='Navbar-Menu'>
         <div><button onClick={() => navigate('/adminhome')} className="navbar-link">Home</button></div>
         <div><button onClick={() => navigate('/users')} className="navbar-link">Users</button></div>
         <div className="dropdown" ref={dropdownRef} >
                <button className="navbar-link" onClick={openMenu} >
                  Licenses {isOpen ? <ArrowDropUp /> : <ArrowDropDown />} 
                </button>
                <div className={`dropdown-content ${isOpen ? 'dropdown-content-show' : ''}`}>
                  <button onClick={() => {navigate('/admin-basic');setIsOpen(false);}} className="dropdown-item">Basic</button>
                  <button onClick={() => {navigate('/admin-standard');setIsOpen(false);}} className="dropdown-item">Standard</button>
                  <button onClick={() => {navigate('/admin-premium');setIsOpen(false);}} className="dropdown-item">Premium</button>
                </div>
              </div>
         <div><button onClick={() => navigate('/admin-support')} className="navbar-link">Support</button></div>
       </div>

       <div className='auth-section'>
        <button className="auth-button" onClick={handleLogout}>Logout</button>
       </div>

       <div className={`mobile ${sidebarOpen ? 'mobile-side-open' : ''}`}>
        <button className="close-button" onClick={handleSidebarClose}><CloseIcon/></button>
        <div className='side-menu'>
            <div className='link-div'><h2>Dashboard</h2></div>
            <div className='link-div'><button onClick={() => {navigate('/adminhome');setSidebarOpen(false);}} className="side-navbar-link">Home</button></div>
            <div className='link-div'><button onClick={() => {navigate('/users');setSidebarOpen(false);}} className="side-navbar-link">Users</button></div>

            <div className='sidebar-dropdown link-div'>
                <div style={{width:'100%'}}><button className="side-dropdown-button" onClick={handleSideDropdown}>License {sideDropdown ? <ArrowDropUp /> : <ArrowDropDown />} </button></div>
                <div className={`sidebar-dropdown-content ${sideDropdown ? 'sidebar-dropdown-content-open' : ''}`}>
                    <button onClick={() => {navigate('/admin-basic');setSideDropdown(false);setSidebarOpen(false);}} className="sidebar-dropdown-items">Basic</button>
                    <button onClick={() => {navigate('/admin-standard');setSideDropdown(false);setSidebarOpen(false);}} className="sidebar-dropdown-items">Standard</button>
                    <button onClick={() => {navigate('/admin-premium');setSideDropdown(false);setSidebarOpen(false);}} className="sidebar-dropdown-items">Premium</button>
                </div>
            </div>
            <div className='link-div'><button onClick={() => {navigate('/admin-support');setSidebarOpen(false);}} className="side-navbar-link">Support</button></div>

        </div>
       </div>
    </div>
  )
}

export default Nav