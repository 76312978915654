import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from '../../Dashboard/Table';
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';

const License = () => {
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyLimitExceeded, setKeyLimitExceeded] = useState(false); // New state for key limit
  const columns = ['id','Category', 'key', 'reg_domain', 'status', 'created_at'];
  const user_id = sessionStorage.getItem('user_id');

  useEffect(() => {
    if (user_id) {
      fetchLicenseKeys();
    } else {
      console.error('User ID not found in sessionStorage:', user_id);
    }
  }, [user_id]);

  const fetchLicenseKeys = async () => {
    try {
      const response = await fetch(`https://www.server.torqoise.com/api/licenseKeyPerUser/${user_id}/1/`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data);
      let counter = 1;

      if (Array.isArray(data)) {
        const rearrangedData = data.map((item) => ({
          id: counter++,
          Category: item.key_cat === 1 ? 'Basic' : '',
          key: item.key,
          reg_domain: item.domain_name === null ? 'N/A' : item.domain_name,
          status: item.status === 1 ? (<span style={{fontWeight:'bold',color:'green'}}>Active</span>) : (<span style={{fontWeight:'bold',color:'red'}}>In-Active</span>),
          created_at: new Date(item.created_at).toLocaleString(),
        }));
        setLicenses(rearrangedData);
      } else if (typeof data === 'object' && Object.keys(data).length > 0) {
        setLicenses([data]);
      } else {
        console.error('Received invalid data format:', data);
      }
    } catch (error) {
      console.error('Error fetching license keys:', error);
    }
  };

  const handleGenerateKey = async () => {
    setLoading(true);
    setKeyLimitExceeded(false); // Reset key limit exceeded state before attempting to generate a key
    try {
      const user_id = sessionStorage.getItem('user_id');
      if (!user_id) {
        throw new Error('User ID not found in session');
      }

      const response = await fetch('https://www.server.torqoise.com/api/Generatekey/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: user_id, key_cat: 1 }),
      });

      const data = await response.json();

      if (response.ok) {
        // Simulate a 3-second delay before hiding the loader and showing success toast
        setTimeout(() => {
          setLoading(false);
          toast.success('Key generated successfully!', {
            style: { backgroundColor: 'green', color: 'white', fontSize: 'bolder' },
          });
          fetchLicenseKeys();
        }, 3000); // 3 seconds delay
      } else if (data.error === 'Key limit exceeded') {
        // Simulate a 3-second delay before hiding the loader and showing limit exceeded toast
        setTimeout(() => {
          setLoading(false);
          toast.error('Key limit exceeded. Please contact support.', {
            style: { backgroundColor: 'red', color: 'white', fontSize: 'bolder' },
          });
        }, 3000); // 3 seconds delay
      } else {
        throw new Error('Failed to generate key');
      }
    } catch (error) {
      // Simulate a 3-second delay before hiding the loader and showing error toast
      setTimeout(() => {
        setLoading(false);
        toast.error('Failed to generate key. Please try again.', {
          style: { backgroundColor: 'red', color: 'white', fontSize: 'bolder' },
        });
      }, 3000); // 3 seconds delay
    }
  };

  return (
    <div className="container mt-5">
      <center><h2 style={{ fontWeight: 'bolder' }}>BASIC LICENSE KEYS</h2></center>
      <button style={{ marginLeft: '30px' }} onClick={handleGenerateKey} className="btn btn-primary" disabled={loading}>
        {loading ? 'Generating...' : 'Generate Key'}
      </button>
      <Table
        columns={columns}
        data={licenses}
        tableHeadBgColor="gray"
        searchable={false}
        tableHeadFontFamily="Arial"
      />
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999
        }}>
          <RotatingLines
            strokeColor="white"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default License;
