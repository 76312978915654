import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PersonIcon from '@mui/icons-material/Person';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError('');
    setPasswordError('');

    try {
      const response = await fetch('https://www.server.torqoise.com/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        sessionStorage.setItem('user_id', data.user_id);
        sessionStorage.setItem('email', data.email);
        navigate('/');
      } else {
        if (data.message.includes('Password')) {
          setPasswordError(data.message);
        } else if (data.message.includes('User')) {
          setEmailError(data.message);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4" style={{
          margin: 'auto',
          padding: '30px 50px',
          border: '1px solid gray',
          borderRadius: '20px',
          background: 'lightgray',
          boxShadow: '2px 4px 20px 8px gray',
          marginTop: '60px' // Adds space from the top
        }}>
          <center>
            {/* <h1 style={{ fontWeight: 'bolder' }}>Login</h1> */}
            <PersonIcon style={{color:'blue',fontSize:'100px',border:'2px solid blue',borderRadius:'50%',background:'white'}}/>
            <br />
          </center>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-4 mt-3">
              <label className='mb-2' htmlFor="email" style={{ fontWeight: 'bolder' }}>User/Email</label>
              <br/>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Username'
                required
              />
              {emailError && <span className="text-danger">{emailError}</span>}
            </div>

            <div className="form-group mb-4">
              <label className='mb-2' htmlFor="password" style={{ fontWeight: 'bolder' }}>Password</label>
              <br/>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Password'
                required
              />
              {passwordError && <span className="text-danger">{passwordError}</span>}
            </div>
            <center>
              <button type="submit" className="btn btn-primary mb-3" style={{ fontWeight: 'bolder' }}>Login</button>
              <br />
              <a href='/register'>Click Here !! For Sign-Up!!</a><br/>
              <a href='/passwordreset' style={{ color: 'black' }}>Forget Password!!</a>
            </center>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
