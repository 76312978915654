import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const PasswordReset = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes = 600 seconds
  const [otpExpired, setOtpExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true); // Control form visibility

  useEffect(() => {
    let timer;
    if ((step === 3 || step === 2) && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      setOtpExpired(true);
      setStep(2); // Move back to OTP step
      setTimeLeft(600); // Reset timer to 10 minutes
    }
    return () => clearTimeout(timer);
  }, [timeLeft, step]);

  const showToast = (message, type) => {
    toast[type](message, {
      style: { backgroundColor: type === 'success' ? 'green' : 'red', color: 'white', fontSize: 'bolder' },
      autoClose: 3000, // 3 seconds
    });
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShowForm(false); // Hide form during loading
    setTimeout(async () => {
      try {
        const response = await fetch('https://www.server.torqoise.com/api/verify_email/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        if (data.auth === 1) {
          showToast('Email verified successfully!', 'success');
          setStep(2);
          setTimeLeft(600); // Reset the timer to 10 minutes
          setOtpExpired(false);
        } else {
          showToast(data.message, 'error');
        }
      } catch (error) {
        showToast('Error verifying email', 'error');
      } finally {
        setLoading(false);
        setShowForm(true); // Show form after loading
      }
    }, 3000); // 3 seconds
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShowForm(false); // Hide form during loading
    setTimeout(async () => {
      try {
        const response = await fetch('https://www.server.torqoise.com/api/send-otp/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        if (data.auth === 1) {
          showToast('OTP sent successfully!', 'success');
          setStep(3);
          setTimeLeft(600); // Reset the timer to 10 minutes
          setOtpExpired(false);
        } else {
          showToast(data.error, 'error');
        }
      } catch (error) {
        showToast('Error sending OTP', 'error');
      } finally {
        setLoading(false);
        setShowForm(true); // Show form after loading
      }
    }, 3000); // 3 seconds
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      showToast('Passwords do not match', 'error');
      return;
    }

    setLoading(true);
    setShowForm(false); // Hide form during loading
    setTimeout(async () => {
      try {
        const response = await fetch('https://www.server.torqoise.com/api/verify-otp/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email,
            otp,
            new_password: newPassword,
          }),
        });
        const data = await response.json();
        if (data.auth === 1) {
          setOtp('');
          setNewPassword('');
          setConfirmPassword('');
          showToast('Password reset successfully!', 'success');
          setTimeout(() => {
            window.location.href = '/login';
          }, 2000);
        } else {
          showToast(data.error, 'error');
        }
      } catch (error) {
        showToast('Error resetting password!', 'error');
      } finally {
        setLoading(false);
        setShowForm(true); // Show form after loading
      }
    }, 3000); // 3 seconds
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };

  return (
    <div className='container'>
      <ToastContainer />
      <div className="row justify-content-center">
        <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6">
          <div className="card mt-5" style={{boxShadow: '2px 4px 20px 8px gray'}}>
            <div className="card-header">
              <center><h2 style={{fontWeight:'bold'}}>Password Reset</h2></center>
            </div>
            <div className="card-body">
              {loading && (
                <div className="d-flex justify-content-center mb-3">
                  <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </div>
              )}
              {!loading && showForm && (
                <>
                  {step === 1 && (
                    <form onSubmit={handleEmailSubmit}>
                      <div className="form-group mt-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder='Enter your Email'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <button type="submit" className="btn btn-primary mt-3">Verify</button>
                    </form>
                  )}

                  {step === 2 && (
                    <form onSubmit={handleOtpSubmit}>
                      <div className="form-group mt-3">
                        <p style={{ fontFamily: 'roboto', fontWeight: 'bolder', textAlign: 'center' }}>
                          {otpExpired ? 'OTP expired. Click to resend OTP' : 'Click to send OTP'}
                        </p>
                        <button type="submit" className="btn btn-primary mt-2 d-block mx-auto">
                          {otpExpired ? 'Resend OTP' : 'Send OTP'}
                        </button>
                      </div>
                    </form>
                  )}

                  {step === 3 && (
                    <>
                      <div className="form-group mt-3 text-center">
                        <p><strong>Time left for OTP:</strong> <span style={{ fontWeight: 'bold', color: 'red' }}>{formatTime(timeLeft)}</span></p>
                      </div>
                      <form onSubmit={handlePasswordReset}>
                        <div className="form-group mt-3">
                          <label><strong>OTP:</strong></label>
                          <input
                            type='text'
                            className="form-control"
                            placeholder='Enter email OTP'
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label><strong>New Password:</strong></label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder='Enter New Password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            autoComplete='off'
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label><strong>Confirm Password:</strong></label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder='Enter Confirm Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            autoComplete='off'
                          />
                        </div>
                        <button type="submit" className="btn btn-primary mt-3 d-block mx-auto">Reset</button>
                      </form>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
