import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import NavBar from '../User/UserNav';
import AdminNavbar from '../Admin/Nav';
import './Dashboard.css';

const Dashboard = () => {
  const [userId, setUserId] = useState(null);
  const [name, setName] = useState(null);
  const [role, setRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const user_id = sessionStorage.getItem('user_id');
    if (!user_id) {
      navigate('/login');
    } else {
      setUserId(user_id);
    }
  }, [navigate]);

  useEffect(() => {
    if (userId) {
      getUser();
    }
  }, [userId]);

  const getUser = async () => {
    try {
      const response = await fetch(`https://www.server.torqoise.com/api/users/${userId}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('No Result Found!');
      }
      const data = await response.json();
      setName(data.name);
      setRole(data.role);
      if (data.role === 1) {
        navigate('/adminhome');
      } else {
        navigate('/userhome');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={{ padding: 0, margin: "0", minHeight: '200vh', background: 'white' }}>
      {role === 1 ? <AdminNavbar /> : <NavBar />}
      <div className='container-fluid'>
        {userId ? (
          <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
            <div className='row'>
              <div className='col-12' style={{ padding: 0 }}>
                <p style={{ fontWeight: 'bolder', marginLeft: '100px' }}>Welcome, <span style={{color:'blue'}}>{role==1?'Admin':name}</span></p>
                <Outlet />
              </div>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
