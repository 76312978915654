import React, { useState, useEffect } from 'react';
import Table from '../../Dashboard/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Standard = () => {
  const [allLicenses, setAllLicenses] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentLicense, setCurrentLicense] = useState(null);
  const [licenseToDelete, setLicenseToDelete] = useState(null);
  const [licenseData, setLicenseData] = useState({
    id: '',
    key: '',
    status: 'Active',
  });

  const columns = ['Id','Name','Email', 'Key','key_cat', 'Domain_name', 'Status', 'Created_at', 'Actions'];

  useEffect(() => {
    getLicenses();
  }, []);

  const getLicenses = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/licensekeyswithkeycat/2/');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      // console.log(data);
      let counter = 1;

      if (Array.isArray(data)) {
        const rearrangedData = data.map((item) => ({
          Id: counter++, // Display ID
          originalId: item.id, // Original ID
          Name : item.name,
          Email:item.email,
          key_cat:item.key_cat === 2 ? 'Standard':'',
          Key: item.key,
          Domain_Name: item.domain_name === null ? 'N/A':item.domain_name,
          Status: item.status === 1 ? (<span style={{fontWeight:'bold',color:'green'}}>Active</span>) : (<span style={{fontWeight:'bold',color:'red'}}>In-Active</span>),
          Created_at: new Date(item.created_at).toLocaleString(),
          Actions: '' // Placeholder for actions
        }));
        setAllLicenses(rearrangedData);
      } else if (typeof data === 'object' && Object.keys(data).length > 0) {
        setAllLicenses([{
          Id: counter++,
          originalId: data.id,
          Name : data.name,
          Email:data.email,
          key_cat:data.key_cat === 2 ? 'Standard':'',
          Key: data.key,
          Domain_Name: data.domain_name === null ?'N/A':data.domain_name ,
          Status: data.status === 1 ?(<span style={{fontWeight:'bold',color:'green'}}>Active</span>) : (<span style={{fontWeight:'bold',color:'red'}}>In-Active</span>),
          Created_at: new Date(data.created_at).toLocaleString(),
          Actions: '' // Placeholder for actions
        }]);
      } else {
        console.error('Received invalid data format:', data);
      }
    } catch (error) {
      console.error('Error fetching licenses:', error);
    }
  };

  const handleEdit = async (licenseId) => {
    try {
      const response = await fetch(`http://localhost:8000/api/licensekeys/${licenseId}/`);
      if (!response.ok) {
        throw new Error('Failed to fetch license');
      }
      const data = await response.json();
      setCurrentLicense(licenseId);
      setLicenseData({
        id: data.id,
        key: data.key,
        status: data.status,
      });
      setShowEditModal(true);
    } catch (error) {
      console.error('Error fetching license details:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`http://localhost:8000/api/licensekeys/${licenseData.id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...licenseData,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update license');
      }
      setShowEditModal(false);
      toast.success('License updated successfully!', {
        style: { backgroundColor: 'green', color: 'white', fontSize: 'bolder' },
      });
      getLicenses(); // Refresh the list
    } catch (error) {
      console.error('Error updating license:', error);
      toast.error('Failed to update license. Please try again.', {
        style: { backgroundColor: 'red', color: 'white', fontSize: 'bolder' },
      });
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`http://localhost:8000/api/licensekeys/${licenseToDelete}/`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete license');
      }
      setAllLicenses(allLicenses.filter(license => license.originalId !== licenseToDelete));
      setShowDeleteModal(false);
      toast.success('License deleted successfully!', {
        style: { backgroundColor: 'green', color: 'white', fontSize: 'bolder' },
      });
    } catch (error) {
      console.error('Error deleting license:', error);
      toast.error('Failed to delete license. Please try again.', {
        style: { backgroundColor: 'red', color: 'white', fontSize: 'bolder' },
      });
    }
  };

  const renderActions = (licenseId) => (
    <div>
      <Button variant="primary" style={{ margin: '5px' }} onClick={() => handleEdit(licenseId)}><EditNoteIcon /></Button>
      <Button variant="danger" style={{ margin: '5px' }} onClick={() => {
        setLicenseToDelete(licenseId);
        setShowDeleteModal(true);
      }}><DeleteIcon /></Button>
    </div>
  );

  // Map over allLicenses to include the actions column
  const updatedLicenses = allLicenses.map(license => ({
    ...license,
    Actions: renderActions(license.originalId),
  }));

  return (
    <div>
      <center><h2 style={{fontWeight:'bolder'}}>STANDARD LICENSE KEYS</h2></center>
      <Table
        columns={columns}
        data={updatedLicenses}
        tableHeadBgColor="gray"
        searchable={false}
        tableHeadFontFamily="Arial"
      />
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit License Key</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formId" style={{display:'none'}}>
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="text"
                value={licenseData.id}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="formKey">
              <Form.Label>Key</Form.Label>
              <Form.Control
                type="text"
                value={licenseData.key}
                onChange={(e) => setLicenseData({ ...licenseData, key: e.target.value })}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={licenseData.status}
                onChange={(e) => setLicenseData({ ...licenseData, status: e.target.value })}
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{justifyContent:'center'}}>
          <center><Button variant="primary" onClick={handleUpdate}>update</Button></center>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this license key?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>No</Button>
          <Button variant="danger" onClick={handleDelete}>Yes</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Standard;
