import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const AdminRegistration = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role] = useState(1);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      const response = await fetch('https://www.server.torqoise.com/api/users/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password, role }),
      });

      const data = await response.json();
      
      if (response.ok) {
        setName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        toast.success('Registration successful! Redirecting to login...', {
          style: { backgroundColor: 'green', color: 'black', fontSize: 'bolder' },
        });
        setTimeout(() => {
          navigate('/login');
        }, 2000); 
      } else {
        toast.error(data.message || 'Registration failed');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', {
        style: { backgroundColor: 'red', color: 'white', fontSize: 'bolder' },
      });
    }
  };

  return (
    <div style={{ height: "100%", width: "100%", padding: '20px' }}>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6" style={{
            border: '1px solid gray',
            padding: '30px 50px',
            borderRadius: '20px',
            background: 'lightgray',
            boxShadow: '2px 4px 20px 8px gray'
          }}>
            <center><HowToRegIcon style={{fontSize:'100px',color:'blue',border:'2px solid green',borderRadius:'50%',padding:'10px',background:'white'}}/></center>
            <br />
            <form onSubmit={handleSubmit}>
              <div className="mb-3 mt-3">
                <label htmlFor="name" className="form-label" style={{ fontWeight: 'bolder' }}>Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder='Enter your name'
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label" style={{ fontWeight: 'bolder' }}>Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Enter your email'
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label" style={{ fontWeight: 'bolder' }}>Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder='Enter your password'
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label" style={{ fontWeight: 'bolder' }}>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder='Confirm your password'
                  required
                />
              </div>
              <div className="mb-3" style={{ display: 'none' }}>
                <input
                  type="text"
                  className="form-control"
                  id="role"
                  value={role}
                  required
                />
              </div>
              
              <center>
                <button type="submit" className="btn btn-primary mb-4" style={{ fontWeight: 'bolder' }}>Register</button>
                <br />
                <a href='/login'>Click Here For Sign-in!!</a>
              </center>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminRegistration;
