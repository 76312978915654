import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './Auth/Login';
import Registration from './Auth/Registration';
import Dashboard from './Dashboard/Dashboard';
import Logout from './Auth/Logout';
import PrivateRoute from './Auth/PrivateRoute';
import PublicRoute from './Auth/PublicRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminHome from './Admin/Home';
import Users from './Admin/Users';
import AdminSupport from './Admin/Support/AdminSupport';
import AdminSupportReply from './Admin/Support/AdminSupportReply';
import UserBasic from './User/License/Basic';
import UserStandard from './User/License/Standard';
import UserPremium from './User/License/Premium';
import AdminBasic from './Admin/License/Basic';
import AdminStandard from './Admin/License/AdminStandard';
import AdminPremium from './Admin/License/AdminPremium';
import UserSupport from './User/Support/UserSupport';
import SupportReply from './User/Support/SupportReply';
import PasswordRest from './Auth/PasswordRest';
import AdminRegistration from './Auth/AdminRegistration'

function App() {
  return (
    <div style={{ margin: 0, padding: 0, height: '100%' }}>
      <Routes>
        <Route path="/register" element={<PublicRoute><Registration /></PublicRoute>} />
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/passwordreset" element={<PasswordRest />} />
        <Route path="/adminregister" element={<AdminRegistration />} />

        <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
          <Route path="adminhome" element={<AdminHome />} />
          <Route path="users" element={<Users />} />
          <Route path="admin-basic" element={<AdminBasic/>} />
          <Route path="admin-standard" element={<AdminStandard/>} />
          <Route path="admin-premium" element={<AdminPremium />} />
          <Route path="admin-support" element={<AdminSupport />} />
          <Route path="/admin_support_reply/:ticket_id" element={<AdminSupportReply />} />
          
          <Route path="userhome" element={<UserBasic/>} />
          <Route path="user-standard" element={<UserStandard />} />
          <Route path="user-premium" element={<UserPremium />} />
          <Route path="user-support" element={<UserSupport />} />
          <Route path="/support_reply/:ticket_id" element={<SupportReply />} />
        </Route>

        <Route path="*" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      </Routes>
    </div>
  );
}

export default App;








// import React from 'react';
// import Button from './comp/Button.jsx';
// import Tooltip from './comp/Tooltip.jsx';
// import { setLicenseKey } from './comp/licenseKey';
// import Typography from './comp/Typography.jsx';
// import MultiRangeSlider from './comp/MultiRangeSilder.jsx';
// import Slider from './comp/SliderPicker.jsx';

// function App() {
//     // Set the license key dynamically
//     setLicenseKey('7a0223b764c2d83fa678324a6b72db6acbe3ed8bfa36bf500c842cd6bc60dd0b');

//     const SliderHandle =(val)=>{
//         console.log(val);
//     }
//     const multihandle=(data)=>{
//         console.log(data);
//     }

//     return (
//         <div className="App">
//             <Typography variant="h1">React Components</Typography>
//             <Button variant="success" >Click me</Button>
//             {/* <Slider min={0} max={100} tooltipcolor="red" tooltipbgcolor="blue" onChange={SliderHandle}/>
//             <MultiRangeSlider min={0} max={100} tooltipcolor="red" tooltipbgcolor="blue" onChange={multihandle}/>
//             <Typography>Hello I am heading 
//                     <Tooltip message="Short message" position='top'>
//                             h3
//                     </Tooltip>
//                     </Typography>
//             */}
//         </div>
//     );
// }

// export default App;
