import React, { useState, useEffect } from 'react';
import Table from '../Dashboard/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Users = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [currentUser, setCurrentUser] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userData, setUserData] = useState({
    id: '',
    name: '',
    email: '',
    status: 'Active',
  });

  const columns = ['Id', 'Name', 'Email','Role', 'Status', 'Created_at', 'Actions'];

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const response = await fetch('https://www.server.torqoise.com/api/users/');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      let counter = 1;

      if (Array.isArray(data)) {
        const rearrangedData = data.map((item) => ({
          Id: counter++, // Display ID
          originalId: item.id, // Original ID
          Name: item.name,
          Email: item.email,
          Status: item.status === 1 ? (<span style={{color:'green',fontWeight:'bold'}}>Active</span>) : (<span style={{color:'red',fontWeight:'bold'}}>In-Active</span>),
          Role : item.role === 1 ? 'Admin' : 'User',
          Created_at: new Date(item.created_at).toLocaleString(),
          Actions: '' // Placeholder for actions
        }));
        setAllUsers(rearrangedData);
      } else if (typeof data === 'object' && Object.keys(data).length > 0) {
        setAllUsers([{
          Id: counter++,
          originalId: data.id,
          Name: data.name,
          Email: data.email,
          Status: data.status === 1 ? (<span style={{color:'green',fontWeight:'bold'}}>Active</span>) : (<span style={{color:'red',fontWeight:'bold'}}>In-Active</span>),
          Role : data.role === 1 ? 'Admin' : 'User',
          Created_at: new Date(data.created_at).toLocaleString(),
          Actions: '' // Placeholder for actions
        }]);
      } else {
        console.error('Received invalid data format:', data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleEdit = async (userId) => {
    try {
      const response = await fetch(`https://www.server.torqoise.com/api/users/${userId}/`);
      if (!response.ok) {
        throw new Error('Failed to fetch user');
      }
      const data = await response.json();
      // setCurrentUser(userId);
      setUserData({
        id: data.id,
        name: data.name,
        email: data.email,
        status: data.status,
      });
      setShowEditModal(true);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      // console.log(userData);
      const response = await fetch(`https://www.server.torqoise.com/api/users/${userData.id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...userData,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update user');
      }
      setShowEditModal(false);
      toast.success('User updated successfully!', {
        style: { backgroundColor: 'green', color: 'white', fontSize: 'bolder' },
      });
      getUsers(); // Refresh the list
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Failed to update user. Please try again.', {
        style: { backgroundColor: 'red', color: 'white', fontSize: 'bolder' },
      });
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`https://www.server.torqoise.com/api/users/${userToDelete}/`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete user');
      }
      setAllUsers(allUsers.filter(user => user.originalId !== userToDelete));
      setShowDeleteModal(false);
      toast.success('User deleted successfully!', {
        style: { backgroundColor: 'green', color: 'white', fontSize: 'bolder' },
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Failed to delete user. Please try again.', {
        style: { backgroundColor: 'red', color: 'white', fontSize: 'bolder' },
      });
    }
  };

  const renderActions = (userId) => (
    <div>
      <Button variant="primary" style={{ margin: '5px' }} onClick={() => handleEdit(userId)}><EditNoteIcon /></Button>
      <Button variant="danger" style={{ margin: '5px' }} onClick={() => {
        setUserToDelete(userId);
        setShowDeleteModal(true);
      }}><DeleteIcon /></Button>
    </div>
  );

  // Map over allUsers to include the actions column
  const updatedUsers = allUsers.map(user => ({
    ...user,
    Actions: renderActions(user.originalId),
  }));

  return (
    <div>
      <center><h2 style={{fontWeight:'bolder'}}>USERS</h2></center>
      <Table
        columns={columns}
        data={updatedUsers}
        tableHeadBgColor="gray"
        searchable={true}
        tableHeadFontFamily="Arial"
      />
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontWeight:'bold'}}>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formId" style={{display:'none'}}> 
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="text"
                value={userData.id}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Label style={{fontWeight:'bold'}}>Name :</Form.Label>
              <Form.Control
                type="text"
                value={userData.name}
                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label style={{fontWeight:'bold'}}>Email :</Form.Label>
              <Form.Control
                type="email"
                value={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formStatus">
              <Form.Label style={{fontWeight:'bold'}}>Status :</Form.Label>
              <Form.Control
                as="select"
                value={userData.status}
                onChange={(e) => setUserData({ ...userData, status: e.target.value })}
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{justifyContent:'center'}}>
          <Button variant="primary" onClick={handleUpdate}>Update</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this user?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>No</Button>
          <Button variant="danger" onClick={handleDelete}>Yes</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Users;
