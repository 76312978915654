import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const TableWrapper = styled.div`
  margin: 20px;
  overflow-x: auto;
  border: none;

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    & > table {
      width: 100%;
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
`;


const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th, td {
    padding: 10px 10px;
    border: 1px solid #ddd;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: 14px;
    transition: background-color 0.2s ease;
  }

  th {
    background-color: ${props => `${props.tableHeadBgColor || '#f4f4f4'}`};
    font-family: ${props => `${props.tableHeadFontFamily || 'arial'}`};
    font-size: ${props => `${props.tableHeadFontSize || '14px'}`};
  }

  td {
    background-color: ${props => `${props.tableBodyBgColor || 'transparent'}`};
    font-family: ${props => `${props.tableBodyFontFamily || 'arial'}`};
    font-size: ${props => `${props.tableBodyFontSize || '14px'}`};
  }


  td:hover,
  tbody tr:hover td {
    background-color: #f1f1f1;
  }
  
  td:hover ~ td,
  tbody tr:hover  {
    background-color: #f1f1f1;
  }

  tbody tr:hover {
    background-color: #f1f1f1;
  }

  .sortable {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .column-name {
    display: inline;
    margin-right: 5px;
  }
 th:first-child, td:first-child {
    max-width: 50px;
    width: 50px;
  }
`;

const TableFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  .entries {
    display: flex;
    align-items: center;
  }

  .entries label, .entries select {
    margin-right: 5px;
  }

  .search {
    display: flex;
    align-items: center;
    border-radius: 10px;
  }

  .search input:focus {
    outline: ${props => props.SearchFocusBorderColor || '2px solid #BED7F6'};
    border:none;
  }

  .search label, .search input {
    margin-right: 5px;
    border-radius: 5px;
    padding: 5px 7px;
  }
  .search input{
    box-sizing:border-box;
    border:${props => props.SearchBorderColor || '2px solid #8F9398'};
    background:${props => props.SearchBgColor || 'white'};
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  button {
    margin: ${props => props.PaginationButtonMargin || ' 0 5px'}; 
    padding: ${props => props.PaginationButtonPadding || ' 5px 10px'};
    border: ${props => props.PaginationButtonBorder || '1px solid #ddd'};
    background-color:${props => props.PaginationButtonBgColor || 'gray'};
    color: ${props => props.PaginationButtonTextColor || 'white'}; 
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      background-color: blue;
      color: white;
    }
  }
`;

const Table = ({ columns, data, customStyles, searchable, ...props }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState(data);
  const [sortOrder, setSortOrder] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    let filtered = data;
    if (searchTerm) {
      filtered = filtered.filter(row =>
        columns.some(column =>
          row[column].toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
    setFilteredData(filtered);
  }, [searchTerm, data, columns]);

  const handleSortChange = (column) => {
    const order = sortOrder[column] === 'asc' ? 'desc' : 'asc';
    setSortOrder({ [column]: order });
    const sortedData = [...filteredData].sort((a, b) => {
      if (order === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    setFilteredData(sortedData);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleRowsPerPageChange = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setCurrentPage(1);
  };

  const handleClick = (number) => {
    setCurrentPage(number);
  };

  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const maxPageNumbers = 5;

  const generatePageNumbers = () => {
    let pages = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(currentPage + 2, totalPages);

    if (totalPages > maxPageNumbers) {
      if (currentPage <= 3) {
        endPage = maxPageNumbers;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <TableWrapper style={customStyles} tabIndex={0} aria-label="table component">
      <Controls>
        <div className="entries">
          <label>Show</label>
          <select value={rowsPerPage} onChange={(e) => handleRowsPerPageChange(e.target.value)}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <label>entries</label>
        </div>
        {searchable && (
          <div className="search">
            <label>Search:</label>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </div>
        )}
      </Controls>
      <StyledTable {...props} tabIndex={0} aria-label="table">
        <thead tabIndex={0} aria-label="table header">
          <tr>
            {columns.map((column) => (
              <th key={column} onClick={() => handleSortChange(column)}>
                <div className="sortable">
                  <span className="column-name">{column}</span>
                  {sortOrder[column] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody tabIndex={0} aria-label="table body">
          {currentData.map((row, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column} {...props}>
                  {row[column]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <TableFooter>
        <Pagination {...props} tabIndex={0} aria-label="table pagination">
          <button style={{borderRadius:'5px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1}>
            {'<'}
          </button>
          {generatePageNumbers().map((number) => (
            <button style={{borderRadius:'5px',display:'flex',justifyContent:'center',alignItems:'center'}} key={number} onClick={() => handleClick(number)} disabled={currentPage === number}>
              {number}
            </button>
          ))}
          {totalPages > maxPageNumbers && currentPage + 0 < totalPages && <span>...</span>}
          <button style={{borderRadius:'5px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}>
            {'>'}
          </button>
        </Pagination>
        <div>
          Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length} entries
        </div>
      </TableFooter>
    </TableWrapper>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  customStyles: PropTypes.object,
  searchable: PropTypes.bool,
};

Table.defaultProps = {
  customStyles: {},
  searchable: true,
};

export default Table;
