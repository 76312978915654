import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from '../../Dashboard/Table';


const Standard = () => {
  const [licenses, setLicenses] = useState([]);
  const columns = ['id','Category','key','reg_domain','status','created_at']
  const user_id = sessionStorage.getItem('user_id');

  useEffect(() => {
    if (user_id) {
      fetchLicenseKeys();
    } else {
      console.error('User ID not found in props:', user_id);
    }
  }, [user_id]); 

  const fetchLicenseKeys = async () => {
    try {
      const response = await fetch(`http://localhost:8000/api/licenseKeyPerUser/${user_id}/2/`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data);
      let counter = 1;
  
      if (Array.isArray(data)) {
        // Data is an array, set licenses state directly
        const rearrangedData = data.map((item) => ({
          id: counter++,
          Category : item.key_cat === 2 ? 'Standard' : '',
          key: item.key,
          reg_domain :item.domain_name=== null ? 'N/A':item.domain_name,
          status: item.status === 1 ? (<span style={{fontWeight:'bold',color:'green'}}>Active</span>) : (<span style={{fontWeight:'bold',color:'red'}}>In-Active</span>),
          created_at: new Date(item.created_at).toLocaleString(),
        }));
        setLicenses(rearrangedData);
      } else if (typeof data === 'object' && Object.keys(data).length > 0) {
        // Data is a single object, convert it to an array of one object
        setLicenses([data]);
      } else {
        console.error('Received invalid data format:', data);
      }
    } catch (error) {
      console.error('Error fetching license keys:', error);
    }
  };
  

  
  const handleGenerateKey = async () => {
    try {
      // Retrieve user_id from sessionStorage
      const user_id = sessionStorage.getItem('user_id');
      
      if (!user_id) {
        throw new Error('User ID not found in session');
      }
  
      // Send POST request to generate key
      const response = await fetch('http://localhost:8000/api/Generatekey/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: user_id,key_cat: 2 }),
      });
  
      // Check if request was successful
      if (!response.ok) {
        throw new Error('Failed to generate key');
      }
  
      // Parse response data
      const data = await response.json();
      alert(`Generated Key`);
  
      // Refresh license keys after generating new key
      fetchLicenseKeys();
    } catch (error) {
      console.error('Error generating key:', error);
      alert('Failed to generate key. Please try again.');
    }
  };

 

  return (
    <div className="container mt-5" >
     <center><h2 style={{fontWeight:'bolder'}}>STANDARD LICENSE KEYS</h2></center>
      <button style={{marginLeft:'30px',display:'none'}} onClick={handleGenerateKey} className="btn btn-primary" disabled>Generate Key</button>
      <Table 
        columns={columns}
        data={licenses} 
        tableHeadBgColor="gray"
        searchable={false} 
        tableHeadFontFamily="Arial"
      />
    </div>
  );
};

export default Standard;
